import React from "react";
import Layout from "../components/layout/Layout";

const Error = () => {
  return (
    <Layout isHome={true} title="Page Not Found" showCTA={false}>
      <div
        style={{ minHeight: "70vh" }}
        className="py-24 bg-primaryBlack flex flex-col items-center justify-center"
      >
        <h1 className="text-center text-7xl text-white px-1">
          <span className="text-primaryMagenta">404</span> page not found
        </h1>
      </div>
    </Layout>
  );
};

export default Error;
